.infocontainer {
  position: relative;
  z-index: 0; /* Certifique-se de que a infocontainer seja sobreposta */
  margin-top: 60px;

  h1 {
    font-family: Space Grotesk;
    font-size: 88px;
    font-weight: 600;
    line-height: 112.29px;
    text-align: left;    
    text-align-last: center;
    margin: 0 0 0px 0;
    color: #121212;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(#cdcdcd, 0.4) 1px, transparent 1px),
      linear-gradient(90deg, rgba(#cdcdcd, 0.4) 1px, transparent 1px);
    background-size: 60px 60px;

    /* Máscara para bordas progressivamente transparentes */
    mask-image: radial-gradient(
      ellipse 100% 59% at center,
      rgb(0, 0, 0),
      rgba(0, 0, 0, 0) 100%
    );
    mask-position: center 70%; /* Ajusta a posição vertical para baixo */
    mask-size: cover;

    pointer-events: none; /* Impede a interação com o fundo */
    z-index: -1; /* Fica atrás do conteúdo */
  }
}

.tabs-container {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.tabs-wrapper {
  overflow: hidden;
  position: relative;

  .borda-central {
    position: absolute;
    bottom: 0; /* Fica no final da seção de títulos */
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    width: 32px; /* Defina a largura da linha */
    height: 2px; /* Espessura da linha */
    background-color: #f80091; /* Cor padrão */
    transition: background-color 0.3s ease; /* Suaviza a transição da cor */
  }
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 40px; /* Adicionei gap para espaçamento consistente entre as abas */
  position: relative;
}

.tab {
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s ease;

  .tab-title {
    font-family: Space Grotesk;
    font-size: 48px;
    font-weight: 600;
    line-height: 61.25px;
    text-align: left;
  }

  &:not(.active) {
    .tab-title {
      color: #d1d1d1;
    }
  }
}

.tab.active {
  color: #f80091;
}

.tab-content {
  margin-top: 30px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  padding: 0px 360px;
  margin: 20px 0px;
  box-sizing: border-box;
}

.card {
  padding: 48px;
  background-color: #f2f2f2;
  border-radius: 8px;
  transition: transform 0.1s ease;
  text-align: left;
  align-content: end;
  display: flex; /* Adicionar flexbox */
  flex-direction: column;
  gap: 16px; /* Espaçamento entre o ícone e o texto */
  align-items: flex-start; /* Garante que o ícone e o texto fiquem alinhados ao topo */
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;

  svg {
    margin-bottom: 15px;
  }

  &.large {
    grid-column: span 2;
  }
}

.check-icon {
  width: 24px; /* Define o tamanho do ícone */
  height: auto; /* Mantém a proporção */
  margin-top: 4px; /* Ajuste o espaço entre o ícone e o topo */
}

.card-text {
  display: inline-block;
  line-height: 1.5; /* Melhora a legibilidade do texto */
}

@media (max-width: 768px) {
  .infocontainer {
    margin-top: 60px;
    h1 {
      font-size: 40px;
      line-height: 51.04px;
      text-align: center;
      margin-bottom: 0px;
    }
  }

  .tab{
    padding: 10px 0px;
  }

  .tabs {
    gap: 20px;
  }

  .borda-central{
    top: 65px !important;
    display: none !important;
  }

  .tab-title {
    font-family: Space Grotesk;
    font-size: 40px !important;
    font-weight: 600 !important;
    line-height: 51.04px !important;
    text-align: left;
    white-space: nowrap !important;
  }

  .card-container {
    grid-template-columns: 1fr;
    padding: 0 20px;
    gap: 16px;
    
    .card {
      padding: 24px;
    }
  
    .card.large{
      grid-column: span 1 !important;
    }
  }


  .tabs-container {
    margin-top: 20px;
  }

  .tab-content {
    margin-top: 20px;
  }

  .cube {
    display: none;
  }

}
