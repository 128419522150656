.flex {
  display: flex;
  position: relative;
}

.header {
  padding: 50px 104px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLogo img {
  height: 32px;
}

.headerNav span {
  margin: 0 15px;
  color: white;
  text-decoration: none;
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.97px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.headerNav a:hover {
  opacity: 0.8;
}

.language-select-container {
  position: absolute;
  border-radius: 8px;

  top: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .language-select-option {
    margin-top: 8px;
    display: flex;
    align-items: center;
    padding: 18px 24px;

    border: 0;
    width: 100%;
    color: #121212;

    border-radius: 100px;

    box-sizing: border-box;

    transition: all 0.15s ease;

    &:hover {
      cursor: pointer;
      background: #212121;
      color: #fff;
    }
  }
}

.headerLanguage {
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  color: white;
  position: relative;

  border-radius: 100px;
  border: 1px solid #121212;

  padding: 12px 24px;

  margin-right: 16px;

  font-family: Space Grotesk,
  "sans-serif";
}


.headerLanguage .flag-icon {
  height: 20px;
  margin-right: 5px;
}

.headerLanguage{
}

.headerButton {
  height: 50px;
  margin: auto 0px;
  z-index: 10;
  background-color: #121212;
  color: white;
  border: none;
  padding: 16px 40px;
  border-radius: 100px;
  cursor: pointer;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.86px;
  text-align: center;

}

.headerButton:hover {
  opacity: 0.8;
}

.heroSection {

  .form-dialog {

    .success-dialog {
      width: 100%;
      height: 100%;

      position: absolute;
    }

  }

}

.mobile-header {
  padding: 24px;

  .logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .burger-container {
      display: flex;
      justify-content: center;
      align-items: center;

      border: 2px solid #FFFFFF;
      background-color: transparent;

      width: 48px;
      height: 48px;
      border-radius: 38px;

      img {
        width: 16px;
      }
    }

    .close-container {
      display: flex;
      justify-content: center;
      align-items: center;

      border: 2px solid #000;
      background-color: transparent;

      width: 48px;
      height: 48px;
      border-radius: 38px;

      img {
        width: 16px;
      }
    }
  }

  .header-dialog {
    background: #fff;

    width: 100vw;
    height: 100vh;

    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;

    display: flex;
    align-items: flex-start;

    .dialog-container {
      padding: 24px;
      z-index: 999;
      width: 100%;

      .pink-element {
        background-color: #F90191;

        width: 300px;
        height: 300px;

        position: absolute;
        bottom: 9%;
        left: -54%;
        filter: blur(100px);
      }

      .blue-element {
        background-color: #2D3EFC;

        width: 300px;
        height: 1202px;

        position: absolute;
        bottom: -29%;
        left: 106%;

        filter: blur(100px);
        transform: rotate(18deg);
      }


      .logo-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .close-container img {
          width: 12px;
          height: 12px;
        }
      }

      .content-container {
        width: calc(100% - 4px);
        padding: 24px;
        margin-top: 18px;
        box-sizing: border-box;
        border-radius: 12px;
        position: relative;
        background-color: #fff;

        &::after {
          content: "";
          position: absolute;
          bottom: -2px;
          left: -2px;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          background: linear-gradient(45deg, rgba(152, 72, 255, 1) 0%, rgba(248, 0, 145, 1) 100%);
          border-radius: 15px;
          z-index: -1;
        }

        .language-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 12px;

          button {
            height: 49px;
            width: 100%;
            background-color: #fff;
            border: 2px solid #07001C29;

            padding: 12px 16px 12px 16px;
            border-radius: 58px;

            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;

            font-family: Space Grotesk, "sans-serif";
            font-weight: 700;
            font-size: 12px;
            line-height: 15.31px;
            text-align: center;

            transition: all 0.2s ease-in-out;

            &:active {
              border: 2px solid #07001C;
            }

          }
        }

        .divider {
          width: 100%;
          background-color: #DBDBDB;
          height: 1px;
          margin: 24px 0;
        }

        .ctaButton {
          width: 100%
        }
      }


    }
  }

  .form-dialog {
    background: #fff;

    width: 100vw;
    height: 100vh;

    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;

    display: flex;
    align-items: flex-start;

    overflow: auto;

    .dialog-container {
      padding: 24px;
      z-index: 999;
      width: 100%;

      .logo-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: absolute;

        .close-container img {
          width: 12px;
          height: 12px;
        }
      }

      .profile {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        padding: 24px;

        img {
          width: 88px;
          height: 88px;
          border-radius: 50%;
        }

        .text-info {

          h3 {
            font-family: Space Grotesk;
            font-size: 24px;
            font-weight: 700;
            line-height: 31.2px;
            text-align: center;

            margin: 24px 0 0;

            span {
              font-family: Space Grotesk;
              font-size: 24px;
              font-weight: 700;
              line-height: 31.2px;
              text-align: center;

              background: linear-gradient(to right, #a43ef0, #c800c8);
              -webkit-background-clip: text;
              color: transparent;
            }
          }

          p {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
          }

        }

      }

      .form {

        .form-container {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          margin-bottom: 24px;

          gap: 12px;
        }

        label {

          font-family: Space Grotesk;
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;

          span {
            font-family: Space Grotesk;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            text-align: left;

          }
        }

        input {
          border: 0;
          border-bottom: 1px solid #D1D1D1;
          width: 100%;
          padding: 6px 0;
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
        }

        .ctaButton {
          width: 100%
        }
      }

    }
  }

  .success-dialog {
    background: #fff;

    width: 100vw;
    height: 100vh;

    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;

    display: flex;
    align-items: flex-start;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(rgba(#cdcdcd, 0.4) 1px, transparent 1px),
        linear-gradient(90deg, rgba(#cdcdcd, 0.4) 1px, transparent 1px);
      background-size: 60px 60px;

      /* Máscara para bordas progressivamente transparentes */
      mask-image: linear-gradient(90deg, white, rgba(255, 255, 255, 0) 50%, white 100%);

      mask-position: center 70%;
      /* Ajusta a posição vertical para baixo */
      mask-size: cover;

      pointer-events: none;
      /* Impede a interação com o fundo */
      z-index: -1;
      /* Fica atrás do conteúdo */
    }


    .dialog-container {
      padding: 24px;
      width: 100%;
      height: 100%;
      z-index: 999;
      display: flex;
      align-items: center;

      .logo-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: absolute;
        top: 24px;

        .close-container img {
          width: 12px;
          height: 12px;
        }
      }

      .profile {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        padding: 24px;


        .image-container {
          position: relative;

          img {
            width: 88px;
            height: 88px;
            border-radius: 50%;
          }

          .circle-check {
            height: 32px;
            width: 32px;

            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .text-info {

          h3 {
            font-family: Space Grotesk;
            font-size: 24px;
            font-weight: 700;
            line-height: 31.2px;
            text-align: center;

            margin: 24px 0 0;

            background: linear-gradient(to right, #a43ef0, #c800c8);
            -webkit-background-clip: text;
            color: transparent;
          }

          p {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
          }

        }

        .ctaButton {
          color: #121212;
          background-color: #fff;
          position: relative;
          width: 170px;
          height: 50px;

          margin-top: 16px;

          &::after {
            content: "";
            width: 102.5%;
            height: 54px;
            display: block;
            position: absolute;
            top: -2px;
            left: -1%;
            background: linear-gradient(45deg, rgba(152, 72, 255, 1) 0%, rgba(248, 0, 145, 1) 100%);
            z-index: -1;
            border-radius: 58px;
          }
        }

      }

    }
  }
}