.container {
  display: flex;
  justify-content: left;
  position: relative;

  .img-grid-container {
    position: relative;

    .co-working-image {
      width: 60vw;
      z-index: 1;
    }

    .co-working-gradient-image {
      z-index: 1;
      width: 60vw;
      position: absolute;
      left: 0;
    }

    .risk-squad-logo-gradient {
      position: absolute;
      left: 110px;
      top: 68%;
      z-index: 10;
      width: 314px;
    }
  }

  .white-card-container {
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
          rgba(205, 205, 205, 0.4) 1px,
          transparent 1px
        ),
        linear-gradient(90deg, rgba(205, 205, 205, 0.4) 1px, transparent 1px);
      background-size: 60.1px 60.1px;
      mask-image: none;
      pointer-events: none;
      z-index: -1;
    }

    .white-card {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin: 61px 60px 60px 61px;
      background-color: #fff;
      padding: 35px;
      width: calc(60px * 6);
      height: calc(60px * 8);
      span {
        font-family: Space Grotesk;
        font-size: 32px;
        font-weight: 600;
        line-height: 41.6px;
        text-align: left;
      }
      .ctaButton {
        margin-top: auto;
      }
    }
  }
  .double-arrow-down-icon {
    display: none !important;
  }
}

.descriptionContainer {
  .gradient-text {
    background: linear-gradient(to right, #a43ef0, #c800c8);
    -webkit-background-clip: text;
    color: transparent;
    font-family: Space Grotesk;
    font-weight: 600;
    line-height: 41.6px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-size: 43px;
    margin-top: -6px;
    display: block;
  }
  .blackText {
    color: black;
  }
}

@media (max-width: 768px) {
  .descriptionContainer {
    margin-top: 50px !important;
  }
  .description {
    font-family: Space Grotesk;
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
    text-align: center;
  }
  .white-card-container {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
          rgba(205, 205, 205, 0.4) 1px,
          transparent 1px
        ),
        linear-gradient(90deg, rgba(205, 205, 205, 0.4) 1px, transparent 1px);
      background-size: 10vw 10vw !important;
      mask-image: none;
      pointer-events: none;
      z-index: -1;
    }
    .double-arrow-down-icon {
      width: 24px !important;
      height: 24px !important;
      margin: auto 0 !important;
      display: block !important;
    }
  }
}
