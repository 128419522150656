.featureSection {
  background-color: #121212;
  padding: 0px 104px 60px 104px;
  display: flex;
  flex-direction: column;
  align-items: center;


  .imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 99vw;
      height: 260px;
      pointer-events: none;
      z-index: 11;
    }

    span {
      padding-bottom: 28px;
      font-family: Space Grotesk;
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
      z-index: 10;
      color: white;
      margin-top: -28px;
      background-color: #121212;
    }

    .featureCardsTextShadow {
      background-color: #1A1A1A;
      border: 1px solid #2B2B2B;
      border-radius: 8px;
      padding: 24px 66px;
      z-index: 9;
      font-family: Space Grotesk;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
      color: white;
    }
  }

  .featureCards {
    display: flex;
    justify-content: center;
    min-width: 100%;
    margin-top: 240px;
    gap: 16px;

    justify-content: flex-start;

    .featureCard {
      position: relative;
      width: 248px;

      .lineLeft1 {
        position: absolute;
        top: -365px;
      }

      .lineLeft2 {
        position: absolute;
        top: -365px;
      }

      .lineMiddle {
        position: absolute;
        top: -365px;
      }

      .lineRight1 {
        position: absolute;
        top: -365px;
        right: 50%;
      }

      .lineRight2 {
        position: absolute;
        top: -365px;
        right: 50%;
      }

      .featureCardHeader {
        background-color: #1a1a1a;
        border-radius: 12px;
        border: solid 1px #2b2b2b;
        margin-bottom: 44px;
        position: relative;

        .circleIcon {
          position: absolute;
          left: 50%;
          top: -130px;
          transform: translateX(-50%);
          border-radius: 100%;
          background-color: #121212;
        }

        .cardRectangle {
          position: absolute;
          left: 50%;
          top: -27px;
        }

        .cardRectanglePink {
          position: absolute;
          left: 50%;
          right: 50%;
          transform: translateX(-50%) translateY(-50%);
          top: -2px;
        }

        h3 {
          font-family: Space Grotesk;
          font-size: 20px;
          font-weight: 600;
          color: white;
          margin: 24px 0px;
        }
      }

      .featureCardBody {
        background-color: #1a1a1a;
        border-radius: 12px;
        border: solid 1px #2b2b2b;
        min-width: 200px;
        padding: 24px;
        text-align: left;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        /* Uma coluna */
        gap: 32px;
        /* Espaço entre os itens */
        position: relative;


        .cardCircle {
          position: absolute;
          left: 50%;
          top: -45px;
          transform: translateX(-50%);
        }

        .featureBlock {
          position: relative;
          

          .featureCardTitle {
            font-family: Inter;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: white;

            .text{
              max-width: 172px;
            }

            &:hover {
              text-decoration: underline;
            }

            .titleText{
            text-decoration: underline;

            }
            .questionIcon {
              padding: 0px;
              vertical-align: middle;
              padding: 0px 0px 3px 6px;
            }

            .questionIcon2 {
              border-radius: 100%;
              padding: 0px 6px;
              vertical-align: middle;
            }
          }

          .featureCardTitle:hover+.featureCardDescription {
            display: block;
            position: absolute;

            padding: 12px 16px;
            margin-top: 14px;
            
            background: linear-gradient(180deg,
                #f80091,
                #9848ff,
              );
            border-radius: 4px;

            color: #fff;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            text-align: left;

            z-index: 4;

            &::after {
              content: "";
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 10px solid #f80091;
              position: absolute;
              transform: rotate(-90deg);
              top: -14px;
              left: 15%;
            }
          }

          .featureCardDescription {
            display: none;
          }
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          font-family: Space Grotesk;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #cccccc;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .featureSection {
    padding: 0;

    .imgContainer {
      span {
        font-family: Space Grotesk;
        font-size: 32px;
        font-weight: 600;
        line-height: 41.6px;
        text-align: center;
        padding: 24px;
      }

      img {
        max-width: 100vw;
      }
    }

    .featureCardsContainer {
      width: 100%;
      overflow: scroll;
      box-sizing: border-box;


      &::-webkit-scrollbar {
        display: none;
      }
    }

    .featureCards {
      display: flex;
      justify-content: start;
      flex-direction: row;
      overflow-x: unset !important;
      /* Permite o scroll horizontal */
      flex-wrap: nowrap;
      /* Garante que os cards fiquem em uma linha */
      gap: 16px;
      margin-top: 180px;
      padding-bottom: 90px;
      /* Adiciona espaço para a barra de rolagem */
      scroll-snap-type: x mandatory;

      /* Esconde a barra de rolagem em navegadores suportados */
      -ms-overflow-style: none;
      /* IE 10+ */
      scrollbar-width: none;
      /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .featureCard {
        .line {
          display: none;
          /* Esconde as linhas absolutas no mobile */
        }
      }
    }

    .swiper-pagination {
      bottom: -50px;
    }

    .swiper {
      width: 66%;
      /* Define a largura do Swiper */
      max-width: 1200px;
      /* Limite opcional para centralizar */
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .swiper-pagination-bullet-active {
      color: transparent !important;
      background: linear-gradient(31.98deg, #9848FF 0.22%, #F80091 83.23%);
    }
  }

}