.advantagesSection {
  background-color: #121212;
  background: linear-gradient(
    159deg,
    #f90192 -1.94%,
    rgba(18, 18, 18, 100) 32.51%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .advantagesGridLines {
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(#f80091 1px, transparent 1px),
        /* Linhas horizontais */
          linear-gradient(90deg, #f80091 1px, transparent 1px); /* Linhas verticais */
      background-size: 48px 48px;
      opacity: 0.3; /* Ajuste a opacidade para suavizar o grid */

      /* Máscara com quatro gradientes para controle de transparência nos quatro cantos */
      mask-image: radial-gradient(
          farthest-corner at 10% 10%,
          rgba(0, 0, 0, 0),
          transparent 60%
        ),
        radial-gradient(farthest-corner at 90% 10%, #000000, transparent 60%),
        radial-gradient(farthest-corner at 10% 90%, #000000, transparent 60%),
        radial-gradient(
          farthest-corner at 90% 90%,
          rgba(0, 0, 0, 0),
          transparent 60%
        );

      -webkit-mask-image: radial-gradient(
          farthest-corner at 10% 10%,
          rgba(0, 0, 0, 0),
          transparent 60%
        ),
        radial-gradient(farthest-corner at 90% 10%, #000000, transparent 60%),
        radial-gradient(farthest-corner at 10% 90%, #000000, transparent 60%),
        radial-gradient(
          farthest-corner at 90% 90%,
          rgba(0, 0, 0, 0),
          transparent 60%
        );
      pointer-events: none;
      z-index: 1;
    }
  }

  .advantagesDiv {
    position: relative;
    z-index: 2;
    .advantagesText {
      top: 53%;
      left: 50%;
      z-index: 2;
      font-size: 2.5rem;
      text-align: center;

      h1 {
        font-family: Space Grotesk;
        font-size: 48px;
        font-weight: 600;
        line-height: 62.4px;
        text-align: center;
        color: white;
      }

      .gradient-text {
        background: linear-gradient(189deg, #f80091 26.98%, #263fff 111.29%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
      }
    }
  }

  .advantagesScroll {
    padding: 20px 50px 0px 50px;
    z-index: 2;
    background-color: transparent;
    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;

    -ms-overflow-style: none;

    display: grid;
    grid-auto-flow: column;
    grid-gap: 3vw;
    overflow-x: scroll;

    .advantage-item {
      scroll-snap-align: center;
      text-align: center;
      box-sizing: border-box;
      position: relative;
      z-index: 2;
      &:first-child h2 {
        background: linear-gradient(
          145.14deg,
          #9848ff 11.83%,
          #d8baff 57.53%,
          rgba(152, 72, 255, 0) 93.22%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;

        &::before {
          content: "";
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background: #9d50ff;
          filter: blur(70px);
        }
      }

      &:nth-child(2) h2 {
        background: linear-gradient(
          125.97deg,
          #f80091 9.32%,
          #ff66bf 39.82%,
          rgba(248, 0, 145, 0) 85.57%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;

        &::before {
          content: "";
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background: #f90192;
          filter: blur(70px);
        }
      }

      &:last-child h2 {
        display: flex;
        justify-content: center;
        .percentHolder {
          display: flex;
          align-items: center; /* Alinha verticalmente */
          justify-content: center; /* Alinha horizontalmente */
          .percent {
            margin-left: -1.3em; /* Ajuste este valor para posicionar o símbolo % mais próximo do 0 */
            font-family: Space Grotesk;
            font-size: 79.68px;
            font-weight: 600;
            line-height: 42.84px;
          }
        }
        background: linear-gradient(
          145.14deg,
          #9848ff 11.83%,
          #d8baff 57.53%,
          rgba(152, 72, 255, 0) 93.22%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;

        

        &::before {
          content: "";
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background: #9d50ff;
          filter: blur(70px);
        }
      }

      h2 {
        margin: 0;
        font-family: Space Grotesk;
        font-size: 243.06px;
        font-weight: 400;
        text-align: center;
      }

      p {
        font-family: Space Grotesk;
        font-size: 32px;
        font-weight: 600;
        line-height: 44.8px;
        text-align: center;
        color: white;
        margin-top: 0px;
      }

      &:last-child::after {
        display: none;
      }
    }
  }
  .mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .advantagesGridLines::before {
    display: none !important;
  }

  .advantagesSection {
    background: linear-gradient(125deg, #f90192 -1.94%, rgb(18, 18, 18) 22.51%);
    .advantagesDiv {

      .advantagesText {
        position: unset;
        transform: none;
        h1 {
          font-family: Space Grotesk;
          font-size: 32px;
          font-weight: 600;
          line-height: 41.6px;
          text-align: center;
        }

        .mobile {
          margin-top: 80px !important;
        }
      }
    }
    .advantagesScroll {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      overflow-x: unset !important;
      grid-gap: 0px !important;
      padding: 0px !important;

      .advantage-item {
        p{
          font-size: 24px !important;
        }
        border-bottom: 0.1px solid rgba(255, 255, 255, 0.103);
        box-sizing: border-box;
        &:first-child h2 {
          border-top: 0.1px solid rgba(255, 255, 255, 0.103);

          background: linear-gradient(
            145.14deg,
            #9848ff 11.83%,
            #d8baff 57.53%,
            rgba(152, 72, 255, 0) 93.22%
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;

          &::before {
            content: "";
            position: absolute;
            top: 39%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background: #9d50ffba;
            filter: blur(50px);
          }
        }

        &:nth-child(2) h2 {
          background: linear-gradient(
            125.97deg,
            #f80091 9.32%,
            #ff66bf 39.82%,
            rgba(248, 0, 145, 0) 85.57%
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;

          &::before {
            content: "";
            position: absolute;
            top: 39%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background: rgb(249 1 146 / 61%);
            filter: blur(50px);
          }

        }
        .advantage-item p{
          font-size: 24px !important;
        }

        &:last-child h2 {
          display: flex;
          justify-content: center;
          .percentHolder {
            display: flex;
            align-items: center; /* Alinha verticalmente */
            justify-content: center; /* Alinha horizontalmente */
            .percent {
              margin-left: -1.3em; /* Ajuste este valor para posicionar o símbolo % mais próximo do 0 */
              font-family: Space Grotesk;
              font-size: 79.68px;
              font-weight: 600;
              line-height: 42.84px;
            }
          }

          background: linear-gradient(
            145.14deg,
            #9848ff 11.83%,
            #d8baff 57.53%,
            rgba(152, 72, 255, 0) 93.22%
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;

          &::before {
            content: "";
            position: absolute;
            top: 39%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background: #9d50ffba;
            filter: blur(50px);
          }
        }
        padding: 0;
        h2 {
          font-family: Space Grotesk;
          font-size: 219.68px;
          font-weight: 400;
          text-align: center;
        }
        p {
          color: white;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block !important;
  }
}
