.scrollable-item-mobile {
  .mobile-img-container {
    margin-bottom: 30px;
    .placeholder-image {
      padding: 24px 0px 0px 24px;
      width: calc(100% - 24px);
    }
  }
  .mobile-content {
    text-align: left;
    padding: 0px 24px;
    margin-bottom: 100px;
    .icon {
    }
    h3 {
      font-family: Space Grotesk;
      font-size: 24px;
      font-weight: 600;
      line-height: 31.2px;
      text-align: left;
    }
    p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #7F7D7D;

    }
  }
}
