.scrollable-section {
    margin: 180px auto 0 auto;

    position: relative;
    max-width: 1279px;

}

.scrollable-section {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.scrollable-section::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.img-container {
    padding: 48px 56px 0 56px;
    position: absolute;
    right: 0;
    width: 643px;

    z-index: 4;

    img {
        width: 100%;
        transform: translateY(4px);
    }

    &.fixed {
        position: fixed;
        top: 32px;
        z-index: 4;
    }

    &.fixed-bottom {
        position: absolute;
        bottom: 0;
        right: 0 !important;
        z-index: 4;
    }
}

@media (max-width: 768px) {
    .scrollable-section{
        margin-top: 0px !important;
    }
}