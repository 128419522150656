.scrollable-item-container {
  display: grid;
  grid-template-columns: auto 756px;
  grid-gap: 0px;
  height: 100%;
  overflow: hidden;

  min-height: 973px;
}

.left-panel {
  text-align: start;
  opacity: .5;

  &.visible {
    opacity: 1;
  }

  .content {
    width: 100%;
    max-width: 397px;
  }

  h3 {
    margin-top: 48px;
    margin-bottom: 0;

    font-family: "Space Grotesk", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    color: #121212;
  }

  p {
    margin-top: 12px;

    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    color: #7f7d7d;
  }
}

.right-panel {
  position: relative;

  .img-container {  
    padding: 55px 55px 0 55px;
    position: absolute;

    z-index: 4;

    &.fixed {
      position: fixed;
      top: 0;
      z-index: 4;
    }

    &.bottom-positioned {
      position: absolute;
      opacity: 1;
      top: 100%;
      transform: translateY(-100%);
    }
  
    img {
      transform: translateY(4px);
      max-width: 507px;
      width: 100%;
    }
  }

  img {
    width: 100%;
  }

  .grid-bg {
    position: absolute;
    top: 0;

    height: 100%;
    width: 100%;

    background-image: url("../../assets/images/bg-scroll.png");
    background-repeat: no-repeat;
    background-size: 100% 100.5%;
    background-position: 100% 0%;
  }

  .bottom-img-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 2;
    margin: auto;
  }

  .bottom-img {
    width: 100%;
    position: relative;
    z-index: 4;
    max-height: 353px;
    margin-bottom: 90px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .scrollable-item-container {
    display: block; /* Trocamos o grid por block */
    padding: 20px;
    min-height: auto;
  }

  .left-panel, 
  .right-panel {
    width: 100%;
    text-align: center;
  }

  .right-panel {
    margin-top: 20px;
    position: relative; /* Remove comportamento de posição fixa no mobile */
    .img-container {
      padding: 0;
      position: relative;
      width: 100%;
      margin: 0 auto;
    }
    .bottom-img-container {
      margin-top: 20px;
      width: 100%;
    }
  }

  .left-panel h3 {
    font-size: 24px;
  }

  .left-panel p {
    font-size: 16px;
  }
}
