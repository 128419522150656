/* Estilo principal da seção */
.heroSection {
  position: relative;
  background: linear-gradient(102.45deg, #f80091 26.98%, #263fff 99.29%);
  width: 100%;
  height: 933px;
  overflow: hidden;
  padding-bottom: 95px;
  .chartContainer {
    display: none;
    min-height: 758px;
    position: relative;
    width: 1224px;
    margin: 0 auto;
    .background {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
    .image {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 26px;
      -webkit-box-shadow: 0 242px 131px -165px rgb(221 0 248 / 32%);
      -moz-box-shadow: 0 242px 131px -165px rgb(221 0 248 / 32%);
      box-shadow: 0 242px 131px -165px rgb(221 0 248 / 32%);
    }
  
    .processChart {
      position: absolute;
      left: 21.5%;
      bottom: 46px;
    }
  
    .vulnerabilitiesChart {
      position: absolute;
      right: -6.3%;
      bottom: 80px;
      transition: transform 0s;
      -webkit-box-shadow: 32px 29px 46px -3px rgba(248, 0, 145, 0.24);
      -moz-box-shadow: 32px 29px 46px -3px rgba(248, 0, 145, 0.24);
      box-shadow: 32px 29px 46px -3px rgba(248, 0, 145, 0.24);
    }
  
    .chart {
      margin-top: -345px;
    }
  }
}




/* Quadriculado transparente com foco no centro */
.heroSection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Criação do grid */
  background-image: linear-gradient(rgba(255, 255, 255, 0.1) 1px,
      transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 60px 60px;
  /* Tamanho do quadriculado */

  /* Mask para controlar a opacidade nas bordas */
  mask-image: linear-gradient(to right,
      transparent,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 1) 70%,
      transparent);
  mask-image: linear-gradient(to bottom,
      transparent,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 1) 70%,
      transparent);

  pointer-events: none;
  /* Ignora eventos de mouse */
  z-index: 1;
  /* Quadriculado sobre o fundo */
}

.heroSection .heroLeft,
.heroSection .heroRight {
  position: relative;
  z-index: 2;
  /* Conteúdo acima do quadriculado */
}

.heroContent {
  max-width: 1190px;

  margin: auto;
}

.heroContainer {
  display: flex;
}

/* Estilo da coluna esquerda */
.heroLeft {
  flex: 1;
  max-width: 50%;

  h1 {
    font-family: Space Grotesk;
    font-size: 88px;
    font-weight: 600;
    text-align: left;
    color: white;
    margin: 0;
  }
}

.trialButton {
  background: none;
  border: 2px solid white;
  border-radius: 60px;

  font-family: Space Grotesk, "sans-serif";
  color: white;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;

  margin: 40px 0px;

  width: 227px;
  height: 40px;

  gap: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 16px;
  margin: 0 174px 39px 0;
}

.trialButton .nowBadge {
  background-color: blue;
  border-radius: 20px;
  font-size: 12px;
  color: white;

  height: 24px;
  width: 43px;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* Estilo da coluna direita */
.heroRight {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.heroRight p {
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  color: white;
  margin-bottom: 65px;
}

.ctaButtons {
  display: flex;
  gap: 16px;
}

.ctaButton {
  height: 71px;
  font-family: Space Grotesk;
  background: #121212;
  color: white;
  border: none;
  border-radius: 68px;
  padding: 24px 64px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  line-height: 22.97px;
  text-align: center;
}

.ctaButton:hover {
  opacity: 0.8;
}


.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .burger-container {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid #FFFFFF;
    background-color: transparent;

    width: 48px;
    height: 48px;
    border-radius: 38px;

    img {
      width: 16px;
    }
  }

  .close-container {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid #000;
    background-color: transparent;

    width: 48px;
    height: 48px;
    border-radius: 38px;

    img {
      width: 16px;
    }
  }
}

.header-dialog {
  background: #fff;

  overflow-x: hidden;

  animation: slide-in .15s;
  transform: translateX(0%);

  &.close {
    animation: slide-out .15s;
    transform: translateX(100%);
  }

  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  align-items: flex-start;

  .dialog-container {

    width: 100%;

    .pink-element {
      background-color: #F90191;

      width: 300px;
      height: 300px;

      position: absolute;
      bottom: 9%;
      left: -54%;
      filter: blur(100px);
    }

    .blue-element {
      background-color: #2D3EFC;

      width: 300px;
      height: 1202px;

      position: absolute;
      bottom: -29%;
      left: 106%;

      filter: blur(100px);
      transform: rotate(18deg);
    }


    .logo-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close-container img {
        width: 12px;
        height: 12px;
      }
    }

    .content-container {
      width: calc(100% - 4px);
      padding: 24px;
      margin-top: 18px;
      box-sizing: border-box;
      border-radius: 12px;
      position: relative;
      background-color: #fff;

      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(45deg, rgba(152, 72, 255, 1) 0%, rgba(248, 0, 145, 1) 100%);
        border-radius: 15px;
        z-index: -1;
      }

      .language-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        button {
          height: 49px;
          width: 100%;
          background-color: #fff;
          border: 2px solid #07001C29;

          padding: 12px 16px 12px 16px;
          border-radius: 58px;

          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;

          font-family: Space Grotesk, "sans-serif";
          font-weight: 700;
          font-size: 12px;
          line-height: 15.31px;
          text-align: center;

          transition: all 0.2s ease-in-out;

          &:active {
            border: 2px solid #07001C;
          }

        }
      }

      .divider {
        width: 100%;
        background-color: #DBDBDB;
        height: 1px;
        margin: 24px 0;
      }

      .ctaButton {
        width: 250px
      }
    }


  }
}

.backdrop {
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.form-dialog {
  z-index: 20;
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  align-items: flex-start;

  overflow: auto;

  .dialog-container {
    min-height: 100vh;
    padding-bottom: 32px;
    width: 40%;
    background-color: #fff;
    margin-left: auto;

    animation: slide-in .15s;
    transform: translateX(0%);

    &.close {
      animation: slide-out .15s;
      transform: translateX(100%);
    }

    .form-title2 {
      background: linear-gradient(45.69deg, #9848FF 43.59%, #F80091 69.93%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 24px;
      padding-top: 24px;
      padding-bottom: 30px;
      border-bottom: 1px solid #D1D1D1;

      .logo {
        filter: invert(1);
        position: absolute;
      }

      .close-container {
        margin-right: auto;
      }

      .close-container img {
        width: 12px;
        height: 12px;
      }
    }

    .profile {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      padding: 8px 104px;

      img {
        width: 88px;
        height: 88px;
        border-radius: 50%;
      }

      .text-info {

        h3 {
          font-family: Space Grotesk;
          font-size: 24px;
          font-weight: 700;
          line-height: 31.2px;
          text-align: center;

          margin: 24px 0 0;

          span {
            font-family: Space Grotesk;
            font-size: 24px;
            font-weight: 700;
            line-height: 31.2px;
            text-align: center;

            background: linear-gradient(to right, #a43ef0, #c800c8);
            -webkit-background-clip: text;
            color: transparent;
          }
        }

        p {
          color: #121212;

          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
        }

      }

    }

    .form {
      display: flex;
      flex-direction: column;
      padding: 0px 140px;

      .form-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-bottom: 24px;

        gap: 12px;
      }

      label {

        font-family: Space Grotesk;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;

        span {
          font-family: Space Grotesk;
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;

        }
      }

      input {
        border: 0;
        border-bottom: 1px solid #D1D1D1;
        width: 100%;
        padding: 6px 0;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
      }

      .ctaButton {
        width: 250px;
        max-height: 70px;
        margin-right: auto;
      }
    }

  }
}

.success-dialog {
  overflow: hidden;
  background: #fff;

  width: 100vw;
  height: 100vh;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  align-items: flex-start;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(#cdcdcd, 0.4) 1px, transparent 1px),
      linear-gradient(90deg, rgba(#cdcdcd, 0.4) 1px, transparent 1px);
    background-size: 60px 60px;

    /* Máscara para bordas progressivamente transparentes */
    mask-image: linear-gradient(90deg, white, rgba(255, 255, 255, 0) 50%, white 100%);

    mask-position: center 70%;
    /* Ajusta a posição vertical para baixo */
    mask-size: cover;

    pointer-events: none;
    /* Impede a interação com o fundo */
    z-index: -1;
    /* Fica atrás do conteúdo */
  }

  .dialog-container {

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .logo-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      .close-container img {
        width: 12px;
        height: 12px;
      }
    }

    .profile {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      padding: 140px;
      height: 100%;


      .form-title2 {
        margin: 0px;
        padding: 0px;
      }



      .image-container {
        position: relative;

        img {
          width: 88px;
          height: 88px;
          border-radius: 50%;
        }

        .circle-check {
          height: 32px;
          width: 32px;

          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .text-info {

        h3 {
          font-family: Space Grotesk;
          font-size: 24px;
          font-weight: 700;
          line-height: 31.2px;
          text-align: center;

          margin: 24px 0 0;

          background: linear-gradient(to right, #a43ef0, #c800c8);
          -webkit-background-clip: text;
          color: transparent;
        }

        p {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
        }

      }

      .ctaButton {
        color: #121212;
        background-color: #fff;
        position: relative;
        width: 170px;
        height: 50px;

        margin-top: 16px;

        &::after {
          content: "";
          width: 102%;
          height: 54px;
          display: block;
          position: absolute;
          top: -2px;
          left: -1%;
          background: linear-gradient(45deg, rgba(152, 72, 255, 1) 0%, rgba(248, 0, 145, 1) 100%);
          z-index: -1;
          border-radius: 58px;
        }
      }

    }

  }
}

@media (max-width: 768px) {
  .heroSection {
    height: 100vh;
    padding-bottom: 0px !important;
    // padding: 20px;
    .chartContainer {
      display: block;
      min-height: unset !important;
      width: unset !important;
      position: unset !important;
      .chart{
        margin-top: 0px !important;
        margin-bottom: 60px;
        position: relative !important;
        .background{
          left: unset !important;
          transform: unset !important;
          width: 98vw !important;
          left: -4.7% !important;
          margin-top: -2px;
        }
        .image{
          margin-top: 10px;
  
          position: unset !important;
          left: 0px !important;
          transform: unset !important;
          width: 90vw !important;
        }
        .processChart{
          width: 45vw;
          left: 22%;
          bottom: 6px;
        }
        .vulnerabilitiesChart{
          width: 20vw;
          right: 0.6%;
          top: 104px;
        }
      }
    }
  }


  .heroContent {
    max-width: 100%;
    padding: 0px 20px;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .heroContainer {
    flex-direction: column;
    align-items: center;
  }

  .heroLeft {
    max-width: 100%;
    text-align: center;

    h1 {
      font-family: Space Grotesk;
      font-size: 32px;
      font-weight: 600;
      line-height: 41.6px;
      text-align: center;
    }
  }

  .trialButton {
    margin: 20px auto 50px auto;
  }

  .heroRight {
    max-width: 100%;
    align-items: center;
    text-align: center;
    .ctaButton {
      padding: 16px 52px;
      font-size: 16px;
      height: auto;
    }
  }

  .heroRight p {
    margin-bottom: 30px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }

  .ctaButtons {
    flex-direction: column;
    gap: 10px;
  }

  .ctaButton {
    padding: 16px 32px;
    font-size: 16px;
    height: auto;
  }

  .dialog-container {
    width: 100% !important;

    .profile {
      padding: 20px !important;
    }

    .form {
      padding: 0px 20px 20px 20px !important;
    }

    .logo-container {
      padding-bottom: 0px !important;
      width: unset !important;
      border: none !important;

      .logo {
        display: none !important;
      }
    }

    .ctaButton {
      width: 250px !important;
    }
  }
}

@keyframes slide-in {

  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {

  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}