.footer {
  /* Gradiente de fundo com ajustes nas posições dos radial gradients */
  background: radial-gradient(circle at 15% 159%, #f80091, transparent 60%),
    radial-gradient(circle at 147% 117%, #263fff, transparent 66%);
  padding: 40px 40px 0px 40px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .footerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    .footerLogo {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        margin-right: 10px;
        filter: invert(1);
      }

      span {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .footerButton {
      padding: 10px 20px;
      border: 2px solid #000;
      background-color: transparent;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      border-radius: 50px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }

  .footerSeparator {
    width: 100%;
    max-width: 1200px;
    border: none;
    height: 1px;
    background-color: #0000002f;
    margin: 20px 0;
  }

  .footerBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    .footerText {
      font-family: Space Grotesk;
      font-size: 16px;
      font-weight: 600;
      line-height: 12px;
      text-align: left;
    }

    .footerLinks {
      display: flex;
      gap: 20px;

      font-family: Space Grotesk;
      font-size: 14px;
      font-weight: 700;
      line-height: 17.86px;
      text-align: right;

      a {
        color: #000;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .bigTextFooter {
    margin-top: 200px;
    width: 100vw;
  }
}

.header-dialog {
  background: #fff;

  overflow-x: hidden;

  animation: slide-in .15s;
  transform: translateX(0%);

  &.close {
    animation: slide-out .15s;
    transform: translateX(100%);
  }

  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  align-items: flex-start;

  .dialog-container {

    width: 100%;

    .pink-element {
      background-color: #F90191;

      width: 300px;
      height: 300px;

      position: absolute;
      bottom: 9%;
      left: -54%;
      filter: blur(100px);
    }

    .blue-element {
      background-color: #2D3EFC;

      width: 300px;
      height: 1202px;

      position: absolute;
      bottom: -29%;
      left: 106%;

      filter: blur(100px);
      transform: rotate(18deg);
    }


    .logo-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close-container img {
        width: 12px;
        height: 12px;
      }
    }

    .content-container {
      width: calc(100% - 4px);
      padding: 24px;
      margin-top: 18px;
      box-sizing: border-box;
      border-radius: 12px;
      position: relative;
      background-color: #fff;

      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(45deg, rgba(152, 72, 255, 1) 0%, rgba(248, 0, 145, 1) 100%);
        border-radius: 15px;
        z-index: -1;
      }

      .language-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        button {
          height: 49px;
          width: 100%;
          background-color: #fff;
          border: 2px solid #07001C29;

          padding: 12px 16px 12px 16px;
          border-radius: 58px;

          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;

          font-family: Space Grotesk, "sans-serif";
          font-weight: 700;
          font-size: 12px;
          line-height: 15.31px;
          text-align: center;

          transition: all 0.2s ease-in-out;

          &:active {
            border: 2px solid #07001C;
          }

        }
      }

      .divider {
        width: 100%;
        background-color: #DBDBDB;
        height: 1px;
        margin: 24px 0;
      }

      .ctaButton {
        width: 250px
      }
    }


  }
}

.backdrop {
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.form-dialog {
  z-index: 20;
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  align-items: flex-start;

  overflow: auto;

  .dialog-container {
    min-height: 100vh;
    padding-bottom: 32px;
    width: 40%;
    background-color: #fff;
    margin-left: auto;

    animation: slide-in .15s;
    transform: translateX(0%);

    &.close {
      animation: slide-out .15s;
      transform: translateX(100%);
    }

    .form-title2 {
      background: linear-gradient(45.69deg, #9848FF 43.59%, #F80091 69.93%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 24px;
      padding-top: 24px;
      padding-bottom: 30px;
      border-bottom: 1px solid #D1D1D1;

      .logo {
        filter: invert(1);
        position: absolute;
      }

      .close-container {
        margin-right: auto;
      }

      .close-container img {
        width: 12px;
        height: 12px;
      }
    }

    .profile {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      padding: 8px 104px;

      img {
        width: 88px;
        height: 88px;
        border-radius: 50%;
      }

      .text-info {

        h3 {
          font-family: Space Grotesk;
          font-size: 24px;
          font-weight: 700;
          line-height: 31.2px;
          text-align: center;

          margin: 24px 0 0;

          span {
            font-family: Space Grotesk;
            font-size: 24px;
            font-weight: 700;
            line-height: 31.2px;
            text-align: center;

            background: linear-gradient(to right, #a43ef0, #c800c8);
            -webkit-background-clip: text;
            color: transparent;
          }
        }

        p {
          color: #121212;

          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
        }

      }

    }

    .form {
      display: flex;
      flex-direction: column;
      padding: 0px 140px;

      .form-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-bottom: 24px;

        gap: 12px;
      }

      label {

        font-family: Space Grotesk;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;

        span {
          font-family: Space Grotesk;
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;

        }
      }

      input {
        border: 0;
        border-bottom: 1px solid #D1D1D1;
        width: 100%;
        padding: 6px 0;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
      }

      .ctaButton {
        width: 250px;
        max-height: 70px;
        margin-right: auto;
      }
    }

  }
}

.success-dialog {
  overflow: hidden;
  background: #fff;

  width: 100%;
  height: 100vh;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  align-items: flex-start;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(#cdcdcd, 0.4) 1px, transparent 1px),
      linear-gradient(90deg, rgba(#cdcdcd, 0.4) 1px, transparent 1px);
    background-size: 60px 60px;

    /* Máscara para bordas progressivamente transparentes */
    mask-image: linear-gradient(90deg, white, rgba(255, 255, 255, 0) 50%, white 100%);

    mask-position: center 70%;
    /* Ajusta a posição vertical para baixo */
    mask-size: cover;

    pointer-events: none;
    /* Impede a interação com o fundo */
    z-index: -1;
    /* Fica atrás do conteúdo */
  }

  .dialog-container {

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .logo-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      .close-container img {
        width: 12px;
        height: 12px;
      }
    }

    .profile {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      padding: 140px;
      height: 100%;


      .form-title2 {
        margin: 0px;
        padding: 0px;
      }



      .image-container {
        position: relative;

        img {
          width: 88px;
          height: 88px;
          border-radius: 50%;
        }

        .circle-check {
          height: 32px;
          width: 32px;

          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .text-info {

        h3 {
          font-family: Space Grotesk;
          font-size: 24px;
          font-weight: 700;
          line-height: 31.2px;
          text-align: center;

          margin: 24px 0 0;

          background: linear-gradient(to right, #a43ef0, #c800c8);
          -webkit-background-clip: text;
          color: transparent;
        }

        p {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
        }

      }

      .ctaButton {
        color: #121212;
        background-color: #fff;
        position: relative;
        width: 170px;
        height: 50px;

        margin-top: 16px;

        &::after {
          content: "";
          width: 102%;
          height: 54px;
          display: block;
          position: absolute;
          top: -2px;
          left: -1%;
          background: linear-gradient(45deg, rgba(152, 72, 255, 1) 0%, rgba(248, 0, 145, 1) 100%);
          z-index: -1;
          border-radius: 58px;
        }
      }

    }

  }
}

@media (max-width: 768px) {
  .footer {
    background: radial-gradient(circle at -37% 56%, #f80091, transparent 64%), radial-gradient(circle at 109% 93%, #263fff, transparent 60%);
    padding: 20px;
    .footerTop {
      flex-direction: column;
      align-items: flex-start;
      .footerLogo {
        margin: 0 auto;
        margin-bottom: 50px !important;
      }
      .footerButton {
        margin: 0 auto;
        padding: 20px 40px;
      }
    }
    .footerBottom {
      flex-direction: column-reverse;
      align-items: center;

      .footerLinks {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1843137255);
        width: 100%;
        justify-content: center;
      }
      .footerText {
        padding-top: 30px;
        font-family: Space Grotesk;
        font-size: 14px;
        font-weight: 600;
        line-height: 12px;
        text-align: left;
        margin-bottom: 100px;
      }
    }
    .bigTextFooter{
      display: none !important;
    }
  }
}
