.chartContainer {
  min-height: 758px;
  position: relative;
  width: 1224px;
  margin: 0 auto;
  .background {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
  .image {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 26px;
    -webkit-box-shadow: 0 242px 131px -165px rgb(221 0 248 / 32%);
    -moz-box-shadow: 0 242px 131px -165px rgb(221 0 248 / 32%);
    box-shadow: 0 242px 131px -165px rgb(221 0 248 / 32%);
  }

  .processChart {
    position: absolute;
    left: 21.5%;
    bottom: 46px;
  }

  .vulnerabilitiesChart {
    position: absolute;
    right: -6.3%;
    bottom: 80px;
    transition: transform 0s;
    -webkit-box-shadow: 32px 29px 46px -3px rgba(248, 0, 145, 0.24);
    -moz-box-shadow: 32px 29px 46px -3px rgba(248, 0, 145, 0.24);
    box-shadow: 32px 29px 46px -3px rgba(248, 0, 145, 0.24);
  }

  .chart {
    margin-top: -345px;
  }
}

.descriptionContainer {
  .description {
    font-family: Space Grotesk;
    font-size: 56px;
    font-weight: 600;
    line-height: 70.2px;
    text-align: center;
    margin-bottom: 24px;
    margin-top: -10px;
  }

  .gradient-text {
    background: linear-gradient(to right, #a43ef0, #c800c8);
    -webkit-background-clip: text;
    color: transparent;
  }

  .black-text {
    color: black;
  }

  .descriptionInfo {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-bottom: 76px;
  }
}

.mobile {
  display: none !important;
}
.desktop {
  display: block !important;
}

@media (max-width: 768px) {
  .mobile {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
  .risk-squad-logo-gradient {
    position: absolute !important;
    height: 100px !important;
    width: 100px !important;
    top: -17% !important;
    left: 50% !important;
    transform: translate(-50%);
  }
  .infoSection {
    overflow: hidden;
  }
  .chartContainer {
    min-height: unset !important;
    width: unset !important;
    position: unset !important;
    .chart{
      margin-top: 0px !important;
      position: relative !important;
      .background{
        left: unset !important;
        transform: unset !important;
        width: 98vw !important;
        left: 1% !important;
        margin-top: 10px;
      }
      .image{
        margin-top: 10px;

        position: unset !important;
        left: 0px !important;
        transform: unset !important;
        width: 90vw !important;
      }
      .processChart{
        width: 45vw;
        left: 25%;
        bottom: 0px;
      }
      .vulnerabilitiesChart{
        width: 20vw;
        right: 4.7%;
        top: 80px;
      }
    }
  }
  .descriptionContainer {
    padding: 0px 31px;
    margin-bottom: 30px;
    .description {
      font-family: Space Grotesk;
      font-size: 32px;
      font-weight: 600;
      line-height: 41.6px;
      text-align: center;
    }
  }
  .descriptionInfo {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    text-align: center !important;
  }
  .container {
    overflow: hidden;
    .img-grid-container {
        position: relative !important;
      .co-working-image,
      .co-working-gradient-image {
        position: absolute !important;
        width: 140vw !important;
        transform: translate(-14%);
      }

    }
    .white-card-container {
      margin-top: 95.6vw !important;
      z-index: 9;

      .white-card {
        padding: 106px 24px 31px 24px !important;
        width: calc((10vw * 8) - 1vw) !important;
        height: calc(10vw * 10) !important;
        margin: -10vw auto 20vw auto !important;
        .ctaButton {
          margin-top: 0px !important;
        }
        span {
          font-family: Space Grotesk !important;
          font-size: 7vw !important;
          font-weight: 600 !important;
          line-height: 8vw !important;
          text-align: left !important;
        }
      }
    }
  }
}
